<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="xczoqe9gda"
        d="M5.333 6.667C4.6 6.667 4 6.067 4 5.333 4 4.6 4.6 4 5.333 4c.734 0 1.334.6 1.334 1.333 0 .734-.6 1.334-1.334 1.334zm4-1.2c0-2.42-1.766-4.134-4-4.134-2.233 0-4 1.714-4 4.134 0 1.56 1.3 3.626 4 6.093 2.7-2.467 4-4.533 4-6.093zM5.333 0c2.8 0 5.334 2.147 5.334 5.467 0 2.213-1.78 4.833-5.334 7.866C1.78 10.3 0 7.68 0 5.467 0 2.147 2.533 0 5.333 0z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0L16 0 16 16 0 16z"
                transform="translate(-137 -279) translate(105 168) translate(32 104) translate(0 7)"
              />
              <g
                transform="translate(-137 -279) translate(105 168) translate(32 104) translate(0 7) translate(2.667 1.333)"
              >
                <mask id="6li90dcjkb" fill="#fff">
                  <use xlink:href="#xczoqe9gda" />
                </mask>
                <use fill="#000" fill-rule="nonzero" xlink:href="#xczoqe9gda" />
                <g fill="#90A4AE" mask="url(#6li90dcjkb)">
                  <path
                    d="M0 0H16V16H0z"
                    transform="translate(-2.667 -1.333)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
