<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="7jma6d12la"
        d="M13.333 9.333c.734 0 1.327-.6 1.327-1.333l.007-6.667C14.667.6 14.067 0 13.333 0H2.667c-.734 0-1.334.6-1.334 1.333V8c0 .733.6 1.333 1.334 1.333H0v1.334h16V9.333h-2.667zm-10.666-8h10.666V8H2.667V1.333z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0L16 0 16 16 0 16z"
                transform="translate(-137 -381) translate(105 168) translate(32 206) translate(0 7)"
              />
              <g
                transform="translate(-137 -381) translate(105 168) translate(32 206) translate(0 7) translate(0 2.667)"
              >
                <mask id="26jdll8x0b" fill="#fff">
                  <use xlink:href="#7jma6d12la" />
                </mask>
                <use fill="#000" fill-rule="nonzero" xlink:href="#7jma6d12la" />
                <g fill="#90A4AE" mask="url(#26jdll8x0b)">
                  <path d="M0 0H16V16H0z" transform="translate(0 -2.667)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
