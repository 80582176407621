import { useStore } from "@/store/store";
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";

import useAddressFormatter from "@/uses/useAddressFormatter";
import useUserRoles from "@/uses/useUserRoles";
import { SiteProfile } from "@/api/interfaces/SitesInterfaces";

import EditIcon from "@/assets/icons/Edit.vue";

import CorporateFareIcon from "@/assets/icons/cards/CorporateFare.vue";
import FmdGood from "@/assets/icons/cards/FmdGood.vue";
import CloudDone from "@/assets/icons/cards/CloudDone.vue";
import Computer from "@/assets/icons/cards/Computer.vue";
import Comment from "@/assets/icons/cards/Comment.vue";
import { getCountryByCode } from "@/api/CountriesApi";
import { getDataStorageById } from "@/api/DataStoragesApi";

export default defineComponent({
  name: "ViewSite",
  props: { site: Object as () => SiteProfile },
  components: {
    CorporateFareIcon,
    FmdGood,
    CloudDone,
    Computer,
    Comment,
    EditIcon,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const siteProfile = ref<SiteProfile>();
    const siteId = props.site?.id || "";

    const updateProfile = async () => {
      if (props.site) {
        const country = await getCountryByCode(props.site.country_code);
        const dataStorage = await getDataStorageById(
          props.site.data_storage_id
        );

        siteProfile.value = {
          ...props.site,
          country_name: country.name,
          data_storage_name: dataStorage.name,
        };
      }
    };

    onBeforeMount(() => {
      updateProfile();
    });

    return {
      siteProfile,
      ...useUserRoles(store),
      ...useAddressFormatter(),
      editSite() {
        router.push(`/sites/edit-site/${siteId}`);
      },
    };
  },
});
