<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="rn5kvu1y8a"
        d="M12.9 4.027C12.447 1.727 10.427 0 8 0 6.073 0 4.4 1.093 3.567 2.693 1.56 2.907 0 4.607 0 6.667c0 2.206 1.793 4 4 4h8.667c1.84 0 3.333-1.494 3.333-3.334 0-1.76-1.367-3.186-3.1-3.306zm-.233 5.306H4c-1.473 0-2.667-1.193-2.667-2.666 0-1.367 1.02-2.507 2.374-2.647l.713-.073.333-.634c.634-1.22 1.874-1.98 3.247-1.98 1.747 0 3.253 1.24 3.593 2.954l.2 1 1.02.073c1.04.067 1.854.94 1.854 1.973 0 1.1-.9 2-2 2zm-6-2.546L5.273 5.393l-.94.94 2.334 2.334 4.006-4.007-.94-.94-3.066 3.067z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0L16 0 16 16 0 16z"
                transform="translate(-137 -313) translate(105 168) translate(32 138) translate(0 7)"
              />
              <g
                transform="translate(-137 -313) translate(105 168) translate(32 138) translate(0 7) translate(0 2.667)"
              >
                <mask id="nixpde0fzb" fill="#fff">
                  <use xlink:href="#rn5kvu1y8a" />
                </mask>
                <use fill="#000" fill-rule="nonzero" xlink:href="#rn5kvu1y8a" />
                <g fill="#90A4AE" mask="url(#nixpde0fzb)">
                  <path d="M0 0H16V16H0z" transform="translate(0 -5.333)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
